<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="3" md="4"
          class="mb-md-0 mb-2">
          <b-form-group
            label="Tarihi Başlangıç"
            label-for="dtBaslangicFilter"
          >
            <b-form-input type="date" v-model="dtBaslangicFilter" />
          </b-form-group>
        </b-col>
        <b-col cols="3" md="4">
          <b-form-group
            label="Tarihi Bitiş"
            label-for="dtBitisFilter">

            <b-form-input type="date" v-model="dtBitisFilter" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
</b-card>
</template>

<script>
export default {
  computed: {
    dtBaslangicFilter: {
      get() {
        return this.$store.getters['cariEkstraCustomer/getCariEkstraFilter'].dtBaslangic
      },
      set(value) {
        this.$store.commit('cariEkstraCustomer/setCariEkstraFilter', { key: 'dtBaslangic', value })
      },
    },
    dtBitisFilter: {
      get() {
        return this.$store.getters['cariEkstraCustomer/getCariEkstraFilter'].dtBitis
      },
      set(value) {
        this.$store.commit('cariEkstraCustomer/setCariEkstraFilter', { key: 'dtBitis', value })
      },
    },
  },
}
</script>
