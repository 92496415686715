import { render, staticRenderFns } from "./CariEkstraList.vue?vue&type=template&id=7c2d1fd1&scoped=true&"
import script from "./CariEkstraList.vue?vue&type=script&lang=js&"
export * from "./CariEkstraList.vue?vue&type=script&lang=js&"
import style0 from "./CariEkstraList.vue?vue&type=style&index=0&id=7c2d1fd1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c2d1fd1",
  null
  
)

export default component.exports